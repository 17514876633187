body {
  .modal {
    .modal-dialog {
      width: auto;
      display: table;

      .modal-content {
        border-radius: 0;
        border: 1px solid $red;
        padding: 3em 1.5em 1.5em;
        position: relative;

        &>.icon-close {
          position: absolute;
          top: .5em;
          right: .5em;
          padding: .5em;
          cursor: pointer;

          &:hover {
            color: $red;
          }
        }
      }
    }
  }
}