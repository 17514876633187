.button-primary {
  padding: .5em 1.5em;
  border: 1px solid $red;
  display: table;
  border-spacing: 0;
  text-transform: uppercase;
  letter-spacing: .3em;
  color: $red;
  transition: .3s;
  background: none;
  cursor: pointer;
  text-align: center;
  outline: none !important;

  &.white {
    border-color: #fff;
    color: #fff;

    &:hover {
      background: $red;
      border-color: $red;
    }
  }

  &:hover {
    background: $red;
    color: #fff;
  }

  &.text-black {
    color: #000;

    &:hover {
      color: #fff;
    }

    span {
      text-transform: lowercase;
    }
  }

  &.centered {
    margin: 0 auto 2em;
  }
}

.button-red {
  color: $red;
  font-size: .875em;

  &:hover {
    text-decoration: underline;
  }
}

.h-primary {
  font-family: SegoeUILight;
  font-size: 2.125em;
  letter-spacing: .3em;
  text-transform: uppercase;
  margin-bottom: .5em;

  @media screen and (max-width: $mobile-l) {
    font-size: 1.8em;
    letter-spacing: .15em;
  }
}

.h-secondary {
  font-size: 1.875em;
  margin-top: 0;
  margin-bottom: .75em;

  @media screen and (max-width: $mobile-l) {
    font-size: 1.5em;
  }
}

.h-third {
  font-size: 1.5em;
  letter-spacing: .3em;
  text-transform: uppercase;
  margin-bottom: 1em;

  @media screen and (max-width: $laptop-l) {
    font-size: 1.3em;
  }
}

.date {
  display: table;
  border-spacing: 0;

  span {
    display: table-cell;
    vertical-align: middle;
    color: $text-grey;

    &:last-child {
      font-size: 13px;
      padding-left: .5em;
    }
  }
}

section {
  margin-bottom: 4em;

  @media screen and (max-width: $tablet) {
    margin-bottom: 2em;
  }
}

.share {
  display: table;
  border-spacing: 0;

  &>div {
    display: table-cell;
    vertical-align: middle;
  }

  .share-button {
    cursor: pointer;

    div {
      display: table;
      border-spacing: 0;

      span {
        display: table-cell;
        vertical-align: middle;

        &:first-child {
          color: $text-grey;
          padding-right: 1em;
          font-size: 13px;
        }

        &:last-child {
          color: $red;
          font-size: 2em;
        }
      }
    }
  }

  .list {
    a {
      transition: .3s;
      padding: .3em;
      font-size: 0;

      &:not(:last-child) {
        margin-right: .5em;
      }

      &:hover {
        color: $red;
      }
    }

    &.opened {
      padding-left: 1em;

      a {
        font-size: 1em;
      }
    }
  }
}

.input-gr {
  margin-bottom: .8em;
}

input, textarea {
  padding: .3em .3em;
  border: 1px solid $border-grey;
  outline: none;
  border-radius: 0;
  -webkit-appearance: none;

  &:focus {
    border-color: $red;
  }
}

textarea {
  width: 100% !important;
}

.bordered {
  border: 1px solid $border-grey-default;
  padding: 1.5em 2em;
  margin-bottom: 2em;
}

.download {
  float: right;
  clear: both;
  margin-top: .5rem;

  .t-table {
    border-spacing: 0;
  }

  .t-td {
    vertical-align: middle;
  }

  .t-td:not(.min) {
    color: $text-grey;
    font-size: .875em;
  }

  .min {
    padding-left: .5rem;
    color: $red;
    font-size: 1.5em;
  }
}

.status {
  position: absolute;
  font-size: .875em;
  letter-spacing: .3em;
  padding: .5em 1em .5em 1.3em;
  right: -1px;
  top: -1px;
  text-transform: uppercase;
  color: #fff;

  &.left {
    background: $red;
  }

  &.active {
    background: #17ba5d;
  }
}

.small-h {
  font-size: 1.125em;
  text-transform: uppercase;
  transition: .3s;
}

.grey-small {
  color: $text-grey;
  font-size: .875em;
}

input[type="checkbox"] {
  display:none !important;

  &+label {
    padding-right: 28px;
    position: relative;
    cursor: pointer;
    margin: 0;
    font-weight: normal;
    color: $grey;

    &:before,
    &:after {
      content: '';
      display: table;
      position: absolute;
      right: 4px;
      top: 2px;
      //top: 50%;
      //transform: translateY(-50%);
      border-spacing: 0;
    }

    &:before {
      width: 17px;
      height: 17px;
      border: 1px solid $border-grey;
    }

    &:after {
      opacity: 0;
      content: '\e90e';
      font-family: 'icomoon';
      color: $red;
      transition: .3s;
      margin: 0 2px;
      font-size: 13px;
    }
  }

  &.mark-left {
    &+label {
      padding-right: 0;
      padding-left: 28px;

      &:before,
      &:after {
        left: 4px;
      }
    }
  }

  &:checked + label:after {
    opacity: 1;
  }
}

body {
  .chosen-container {
    //width: auto !important;
    font-size: 1.125em;
  }

  .chosen-container-single .chosen-single {
    border: 1px solid $border-grey;
    background: none;
    box-shadow: none;
    border-radius: 0;
    height: auto;
    line-height: 1;
    padding: .4em;
  }

  .chosen-container-active.chosen-with-drop .chosen-single {
    background: none;
  }

  .chosen-container-single .chosen-single div b {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    font-size: .5em;
    left: 50%;

    &:after {
      content: '\e911';
      font-family: 'icomoon';
    }
  }

  .chosen-container-active.chosen-with-drop .chosen-single div b {
    &:after {
      content: '\e912';
      font-family: 'icomoon';
    }
  }

  .chosen-container .chosen-results {
    font-size: 1em !important;
  }

  .chosen-container-single .chosen-single div {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 26px;
  }

  .chosen-container-single .chosen-drop {
    border-radius: 0;
  }

  .chosen-container .chosen-results {
    padding: 0;
    margin: 0;
    border-top: 1px solid $border-grey;

    &::-webkit-scrollbar-track
    {
      background-color: transparent;
    }

    &::-webkit-scrollbar
    {
      width: 4px;
      background-color: transparent;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb
    {
      background-color: $red;
      border-radius: 5px;
    }
  }

  .chosen-container .chosen-results li {
    padding: .3em .4em;
    white-space: nowrap;
  }

  .chosen-container .chosen-results li.highlighted {
    background: $red;
  }

  .chosen-search {
    display: none;
  }
}

.structure {
  margin: 2em auto;
}

ul.red-list {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    padding-left: 1.2em;
    position: relative;
    margin-bottom: .3em;

    &:before {
      content: '';
      height: .35em;
      width: .35em;
      position: absolute;
      left: .25em;
      top: .5em;
      background: $red;
      border-radius: 100%;
    }
  }
}