.trainings-about {
  margin: 2em 0;

  .row > div:nth-of-type(3n+1) {
    clear: both;
  }

  h4 {
    letter-spacing: .3em;
    padding-left: .3em;
    text-align: center;
  }

  .text {
    text-align: center;
  }
}