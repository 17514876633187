.contact-us {
  margin-top: 2em;

  &>.title {
    font-size: 1.125em;
    margin-bottom: 1.5rem;
  }

  .single-contact {
    display: table;
    width: 100%;
    margin-bottom: 1em;

    &>* {
      display: inline-block;
      vertical-align: middle;
    }

    span {
      font-size: 1.5em;
      color: $red;
      margin-right: 1rem;
    }

    a {
      font-family: SegoeUISemiBold;
      font-size: 1.125em;
    }
  }

  .button-primary {
    margin-top: 2em;
  }
}