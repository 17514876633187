.lightbox {
  .lb-outerContainer {
    .lb-close2 {
      position: absolute;
      top: 1em;
      right: 1em;
      color: #fff;
      cursor: pointer;
      font-size: 1.7em;
      text-shadow: 0 0 4px #000;
      z-index: 999;
    }
  }

  .lb-close {
    display: none !important;
  }
}