.trainings-list {
  .single-training {
    position: relative;
    padding: 3em 4em;
    border: 1px solid $border-grey;
    display: table;
    width: 100%;
    margin-bottom: 2em;

    @media screen and (min-width: $mobile-xl) and (max-width: $tablet) {
      padding: 0;
    }

    @media screen and (max-width: $mobile-xl - 1) {
      max-width: 450px;
      margin: 0 auto 1em;
      padding: 0;
    }

    .image {
      width: 300px;
      max-width: 100%;
      float: left;
      margin-right: 4em;

      @media screen and (min-width: $mobile-xl) and (max-width: $tablet) {
        width: 33vw;
        margin-right: 1.5em;
      }

      @media screen and (max-width: $mobile-xl - 1) {
        width: 100%;
        margin: 0;
      }

      img {
        width: 100%;
      }
    }

    .desc {
      width: calc(100% - 300px - 4em);
      float: left;

      @media screen and (min-width: $mobile-xl) and (max-width: $tablet) {
        width: calc(100% - 33vw - 1.5em);
        padding: 1.5em;
      }

      @media screen and (max-width: $mobile-xl) {
        width: 100%;
        padding: 1.5em;
      }
    }

    h3 {
      font-size: 1.5em;
      margin-bottom: 1em;
      text-transform: uppercase;

      @media screen and (max-width: $mobile-xl) {
        margin-top: 0;
      }

      a{
        transition: .3s;

        &:hover {
          color: $red;
        }
      }
    }

    .rate {
      .rating {
        font-size: 2em;
      }
    }

    .annotation {
      margin-bottom: 1em;
    }

    .button-red {
      margin-bottom: 3em;
      display: table;
    }

    .entry-price {
      float: left;

      .title {
        font-size: .94em;
      }

      .value {
        font-size: 1.75em;
      }
    }

    .share {
      float: right;
      margin-top: 1.5em;
    }
  }
}