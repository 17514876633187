//Размеры экранов
$laptop-l: 1600px;
$laptop-m: 1200px;
$laptop-s: 992px;
$tablet: 768px;
$mobile-xl: 600px;
$mobile-l: 480px;
$mobile-m: 375px;
$mobile-s: 320px;


//Colours
$red: #9c1114;
$grey: #272324;
$text-grey: #6e6e6e;
$border-grey: #cecece;
$border-grey-default: #d0d0d0;