.donate {
  .icon-heart {
    display: inline-block;
    animation: heart linear 1.2s infinite;
  }
}

@keyframes heart {
  0% {
    transform: scale(1);
  }
  40% {
    transform: scale(1);
  }
  50% {
    transform: scale(2.5) translateX(.1em);
    color: $red;
  }
  60% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}