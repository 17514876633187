.rate {
  display: table;
  width: 100%;
  border-spacing: 0;

  .title {
    color: $text-grey;
    margin-left: .5em;
  }

  &>* {
    display: inline-block;
    vertical-align: middle;
  }

  &>span {
    display: inline-block;
    vertical-align: middle;

    &:first-child {
      margin-right: 1em;
    }

    &:last-child {
      font-size: 2em;
    }
  }

  .rating {
    overflow: hidden;
    display: inline-block;
    position: relative;

    .rating-input {
      position: absolute;
      opacity: 0;
      z-index: -9;
    }
  }
  .rating:not(.selected):hover .rating-star:hover,
  .rating:not(.selected):hover .rating-star:hover ~ .rating-star,
  .rating:not(.selected) .rating-star.active,
  .rating-input:checked ~ .rating-star{
    color: $red;
  }
  //,
  //.rating:hover .rating-star
  .rating-star{
    position: relative;
    float: right;
    display: block;
    margin-bottom: 0;
    padding: 0 .1em;
    cursor: pointer;
    color: #b1b1b1;
  }
}