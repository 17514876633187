.border-three {
  margin: -2em -2em 2em -2em;

  @media screen and (max-width: $tablet) {
    margin: 0 0 2em;
  }

  &>.t-table {
    table-layout: fixed;
    border-spacing: 2em;

    @media screen and (max-width: $tablet) {
      border-spacing: 0;
    }

    &>.t-row {
      &>.t-td {
        border: 1px solid $border-grey;
        padding: 1em 1.5em;
        width: 33.33%;
        transition: .3s;
        vertical-align: top;

        @media screen and (max-width: $tablet) {
          width: 100%;
          display: table;
          border-spacing: 0;
          margin-bottom: 1em;
        }

        &.empty {
          border: none;
          visibility: hidden;
        }

        .big-title {
          font-size: 1.75em;
          text-transform: uppercase;
        }

        &:hover {
          //transform: translateY(-3px);

          @media screen and (max-width: $tablet) {
            transform: none;
          }
        }
      }
    }
  }
}