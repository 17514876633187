header {
  @media screen and (max-width: $laptop-s) {
    margin-bottom: 1em;
  }

  @media screen and (max-width: $tablet) {
    background: $red;
    text-align: center;
  }

  .top {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      width: 50%;
      top: 0;
      height: 100%;
      background: $red;
      z-index: -1;
    }

    &:after {
      content: '';
      position: absolute;
      right: 0;
      width: 50%;
      top: 0;
      height: 100%;
      background: $grey;
      z-index: -1;
    }

    @media screen and (max-width: $tablet) {
      &:after,
      &:before {
        display: none;
      }
    }

    .t-td {
      vertical-align: middle;
      padding-top: 1.5em;
      padding-bottom: 1.5em;

      @media screen and (max-width: $tablet) {
        padding: 1em 0;
      }
    }
  }

  .logo {
    background: $red;
    width: 1%;
    padding-right: 1em;

    img {
      max-width: 200px;
    }

    @media screen and (max-width: $tablet) {
      width: 100%;
      text-align: center;
      padding: 2em 4.5em 1em !important;

      img {
        max-width: 100%;
      }
    }
    
    @media screen and (min-width: $tablet) and (max-width: $laptop-s) {
      img {
        max-width: 130px;
      }
    }
  }

  .search {
    background: $red;
    padding-right: 3em;
    padding-left: 1em;

    @media screen and (max-width: $laptop-s) {
      padding-right: 1.5em;
    }

    @media screen and (max-width: $tablet) {
      width: 100%;
      padding: 0;
    }

    .search-block {
      border: 1px solid #fff;
      display: table;
      width: 100%;

      &>div {
        display: table-cell;
        vertical-align: middle;

        &:first-child {
          width: 1%;
        }
      }

      button {
        background: none;
        border: none;
        line-height: 1;
        padding-top: .3em;
      }

      span {
        color: #fff;
        font-size: 1.8em;
        line-height: 1;
      }

      input {
        width: 100%;
        color: #fff;
        background: none;
        border: none;
        padding: .5em 1em .5em 0;
        font-size: .875em;

        &::-webkit-input-placeholder {color:#fff;}
        &::-moz-placeholder          {color:#fff;}
        &:-moz-placeholder           {color:#fff;}
        &:-ms-input-placeholder      {color:#fff;}
      }
    }
  }

  .donate {
    background: $grey;
    padding-right: 1em;
    padding-left: 3em;
    text-align: center;
    width: 1%;

    @media screen and (max-width: $laptop-s) {
      padding-left: 1.5em;
    }

    @media screen and (max-width: $tablet) {
      width: 100%;
      background: none;
    }

    .button-primary {
      margin: 0 auto;
      white-space: nowrap;

      &:hover {
        .icon-heart {
          animation: none;
        }
      }
    }
  }

  .socials {
    background: $grey;
    width: 1%;
    white-space: nowrap;
    padding-right: 3em;
    padding-left: 3em;
    cursor: default;

    @media screen and (max-width: $laptop-s) {
      padding-right: 1em;
      padding-left: 2em;
    }

    @media screen and (max-width: $tablet) {
      width: auto;
      background: none;

      a {
        color: $red;
      }
    }

    a {
      font-size: 1.3em;
      padding: 0 .25rem;
      //transition: .3s;

      &:hover {
        color: #fff;

        span {
          text-shadow: 0 0 transparent;
          //transition: .3s;
        }
      }

      &:not(:last-child) {
        margin-right: 2rem;
      }

      span {
        text-shadow: -1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff;
      }
    }
  }

  .language {
    background: $grey;
    width: 1%;
    white-space: nowrap;
    color: #fff;
    position: relative;
    font-size: 1.25em;

    @media screen and (max-width: $laptop-s) {
      padding-right: 4em;
    }

    @media screen and (max-width: $tablet) {
      width: auto;
      background: none;
      padding-right: 1.5em;
      font-size: 1.3em;
      padding-left: 1em !important;
    }

    &>div {
      padding: 0 .5em;
      position: relative;
      cursor: default;
    }

    &>div:hover {
      .languages-list {
        display: block;
      }
    }

    .languages-list {
      position: absolute;
      top: 100%;
      display: none;
      z-index: 2;
      background: #333;
      width: 100%;
      padding: .4em .5em;
      left: 0;

      a:hover {
        text-decoration: underline;
      }
    }
  }

  .menu {
    margin-bottom: 1.1em;

    @media screen and (max-width: $laptop-s) {
      position: fixed;
      top: 1.6em;
      right: 1em;
      z-index: 9;
    }

    .mobile {
      display: none;

      @media screen and (max-width: $laptop-s) {
        display: table;
        float: right;
        border: 1px solid #ccc;
      }
    }

    nav {
      @media screen and (min-width: $laptop-s + 1px) {
        display: block !important;
      }
      @media screen and (max-width: $laptop-s) {
        display: none;
        clear: both;
        background: #fff;
        border: 1px solid #ccc;
        max-height: calc(100vh - 1.6em - 42px);
        overflow: scroll;
      }
    }

    nav > ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: table;
      width: 100%;

      & > li {
        display: table-cell;
        vertical-align: middle;
        margin-right: 1em;
        text-align: center;

        @media screen and (max-width: $laptop-s) {
          display: block;
          margin: 0;

          .icon-down {
            display: none;
          }
        }

        &.dropdown {
          a {
            &.menu-arrow {
              background: rgba(149, 149, 149, 0.34);
              display: inline-block;
              text-decoration: none;
              width: 3.5em;
              text-align: center;

              span {
                transform: scale(2);
                display: block;

                &:after {
                  content: '+';
                }
              }
            }
          }

          &.open a {
            &.menu-arrow {
              span {
                &:after {
                  content: '-';
                }
              }
            }
          }
        }

        &.active {
          a {
            &:after {
              position: absolute;
              top: -1px;
              left: 50%;
              transform: translateX(-50%);
              content: "\e906";
              font-family: icomoon;
              color: $red;
              line-height: 1;

              @media screen and (max-width: $laptop-s) {
                display: none;
              }
            }

            @media screen and (max-width: $laptop-s) {
              color: $red;
            }
          }
        }

        &:nth-last-child(-n+2) {
          a {
            &:after {
              color: $grey;
            }
          }
        }

        &:first-child {
          //text-align: left;
        }

        &:last-child {
          //text-align: right;
        }

        &>a {
          padding: 1.5em .8em .5em;
          text-transform: uppercase;
          display: inline-block;
          height: 100%;
          transition: .3s;
          position: relative;

          @media screen and (max-width: $laptop-m) {
            padding: 1.5em .5em .5em;
          }

          @media screen and (max-width: $laptop-s) {
            padding: 1em;
          }

          &:hover {
            text-decoration: underline;
          }
        }

        .dropdown-menu {
          background: #fff;
          margin-top: 0;
          left: 50%;
          transform: translateX(-50%);
          box-shadow: none;

          @media screen and (max-width: $laptop-s) {
            width: 100%;
            position: relative;
            left: auto;
            transform: none;
            float: none;
            text-align: center;
          }

          ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
              a {
                padding: .6em .8em;
                display: block;
                text-transform: uppercase;
                transition: .3s;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }

  .hover-menu {
    li.dropdown {
      a:hover {
        &+.dropdown-menu {
          display: block;
        }
      }

      .dropdown-menu:hover {
        display: block;
      }
    }
  }

  .t-table {
    .logo, .search, .socials, .donate, .language {
      @media screen and (max-width: $tablet) {
        display: inline-block;
      }
    }
  }
}