.single-charity {
  text-align: center;
  vertical-align: middle;

  &:hover {
    h4 {
      color: $red;
    }
  }

  h4 {
    transition: .3s;
    font-size: 1.25em;
    letter-spacing: .3em;
    padding-left: .3em;

    @media screen and (max-width: $tablet) {
      letter-spacing: .15em;
      padding-left: .15em;
    }
  }

  .desc {
    font-size: .875em;
    color: $text-grey;
  }
}