.webinars-carousel {
  padding: 0 5rem;
  position: relative;

  @media screen and (max-width: $tablet) {
    padding: 0 4rem;
  }

  .owl-controls {
      .owl-nav {
        &>div {
          position: absolute;
          top: 40%;
          transform: translateY(-50%);
          font-size: 3em;

          &:hover {
            color: $red;
          }

          &.owl-prev {
            left: .3rem;
          }

          &.owl-next {
            right: .3rem;
          }
        }
      }
  }

  h4 {
    font-family: SegoeUISemiBold;
  }
}