.video {
  position: relative;
  background: url(/assets/img/video.jpg);
  background-size: cover;
  overflow: hidden;
  margin-bottom: 2.625em;
  max-height: 100% !important;

  @media screen and (max-width: $tablet) {
    margin-bottom: 1em;
  }

  .video-on-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    @media screen and (max-width: $laptop-s) {
      display: none;
    }

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      @include object-fit(cover);
    }
  }

  .index-video__controls {
    background: rgba(#000, 0.6); /* Old browsers */
    background: -moz-linear-gradient(top, transparent 0%, #000000 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, transparent 0%,#000000 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, transparent 0%,#000000 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    position: absolute;
    height: 5rem;
    width: 100%;
    bottom: 0;
    padding: 3.5rem 1.5rem 1.5rem;
    z-index: 5;
    display: table;

    @media screen and (max-width: $laptop-s) {
      display: none;
    }

    //@media only screen and (max-width: 500px) {
    //  background: #000;
    //  padding: 1rem;
    //  bottom: 0;
    //  height: auto;
    //}

    .controls-block {
      display: table-cell;
      width: calc(100% - 6rem);
      text-align: center;
      vertical-align: bottom;

      #video__play-pause {
        height: 24px;
        display: inline-block;
        margin-right: 1rem;
        vertical-align: middle;
        cursor: pointer;

        img {
          width: auto;
          max-height: 100%;
          max-width: 100%;
        }
      }

      #video__mute {
        height: 24px;
        display: inline-block;
        margin-left: 1rem;
        vertical-align: middle;
        cursor: pointer;

        img {
          width: auto;
          max-height: 100%;
          max-width: 100%;
        }
      }

      /*Chrome*/
      @media screen and (-webkit-min-device-pixel-ratio:0) {
        input[type='range'] {
          cursor: pointer;
          display: inline-block;
          width: 70%;
          vertical-align: middle;
          overflow: hidden;
          -webkit-appearance: none;
          background-color: rgba(#ffffff, 0.6);
        }

        input[type='range']::-webkit-slider-runnable-track {
          height: 10px;
          -webkit-appearance: none;
          color: #ffffff;
          margin-top: -1px;
        }

        input[type='range']::-webkit-slider-thumb {
          width: 10px;
          -webkit-appearance: none;
          height: 10px;
          cursor: ew-resize;
          background: #ffffff;
          box-shadow: -1000px 0 0 1000px #ffffff;
        }

      }
      /** FF*/
      input[type="range"]::-moz-range-progress {
        background-color: #ffffff;
      }
      input[type="range"]::-moz-range-track {
        background-color: rgba(#ffffff, 0.6);
      }
      /* IE*/
      input[type="range"]::-ms-fill-lower {
        background-color: #ffffff;
      }
      input[type="range"]::-ms-fill-upper {
        background-color: rgba(#ffffff, 0.6);
      }
    }
  }

  &>div {
    position: relative;
    z-index: 1;
  }

  img {
    width: 100%;
    max-height: 100%;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #686868;
    opacity: .4;
    z-index: 1;
  }

  .title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: 7em;
    font-family: SegoeUILight;
    color: #fff;
    letter-spacing: .3em;
    text-transform: uppercase;
    z-index: 2;

    @media screen and (max-width: $laptop-l) {
      font-size: 5em;
    }

    @media screen and (max-width: $tablet) {
      font-size: 3em;
    }
  }

  .scroll {
    position: absolute;
    bottom: 4em;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    line-height: 1.7;
    cursor: pointer;
    animation: blink 3s infinite linear;
    text-shadow: 0 0 200px #000, 0 0 200px #000;
    z-index: 2;

    @media screen and (max-width: $laptop-l) {
      bottom: 1em;
    }

    @media screen and (max-width: $tablet) {
      display: none;
    }

    @keyframes blink {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }


    span:first-child {
      font-size: 1.125em;
      font-family: SegoeUILight;
      letter-spacing: .2em;
    }
  }

}