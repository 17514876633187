.banner-title {
  text-align: center;
  padding: 3em 2em;
  position: relative;
  margin-bottom: 2em;

  &.big-pad {
    padding: 6em 2em;

    @media screen and (max-width: $tablet) {
      padding: 4em 2em;
    }
  }

  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    @include object-fit(cover);
  }

  h1 {
    font-size: 3em;
    color: #fff;
    position: relative;
    z-index: 1;
    text-transform: uppercase;
    letter-spacing: .5em;
    padding-left: .5em;
    margin-bottom: 4rem;

    @media screen and (max-width: $tablet) {
      font-size: 2em;
    }

    @media screen and (max-width: $mobile-l) {
      font-size: 1.8em;
      letter-spacing: .15em;
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #686868;
    opacity: .4;
    transition: .3s;
  }

  .text {
    color: #fff;
    position: relative;
    z-index: 1;
    max-width: 788px;
    margin: 0 auto 1em;
  }

  .button-primary {
    margin: 1em auto 0;
    position: relative;
    z-index: 1;
    min-width: 21em;

    @media screen and (max-width: $mobile-l) {
      min-width: 0;
    }
  }

  .similar-links {
    text-align: center;

    .button-primary {
      min-width: 0;
      display: inline-block;
      margin: 1em 0 0;

      @media screen and (max-width: $laptop-l) {
        margin: 1em .5em 0 !important;
      }

      &:not(:last-child) {
        margin-right: 1em;
      }
    }
  }
}