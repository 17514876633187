.activities {
  .t-table-outer {
    @include table(2.625em);
  }

  .t-table {
    min-height: 506px;
    table-layout: fixed;

    @media screen and (max-width: $tablet) {
      min-height: 0;
    }
  }

  .single-activity {
    vertical-align: middle;
    text-align: center;
    padding: 1.5em;
    width: 50%;
    position: relative;

    @media screen and (max-width: $tablet) {
      display: block !important;
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 1em;
      }
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #686868;
      opacity: .6;
      transition: .3s;
    }

    &:hover:after {
      opacity: .5;
    }

    &:first-child {
      background: url('/assets/img/activities/1.jpg') center center;
      background-size: cover;
    }

    &:nth-of-type(2) {
      background: url('/assets/img/activities/2.jpg');
      background-size: cover;
    }

    .title {
      font-size: 1.875em;
      color: #fff;
      position: relative;
      z-index: 1;
      text-transform: uppercase;
      letter-spacing: .5em;
      padding-left: .5em;

      @media screen and (max-width: $tablet) {
        font-size: 1.5em;
      }
    }
  }
}