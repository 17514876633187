.partners {
  .partners-list {
    margin-top: 1em;
    text-align: center;
    max-width: 1200px;

    img {
      display: inline-block;
      margin: 1em 1.5em;
      max-width: 250px;
    }
  }
}