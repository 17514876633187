.training-steps {
  .row > div {
    margin-bottom: 2em;

    &:nth-of-type(2n+1) {
      clear: both;
    }
  }

  .semibold {
    font-family: SegoeUISemiBold;
    font-size: 1.125em;
    margin-bottom: .5em;
  }

  .t-table {
    margin-bottom: 1em;

    .t-td {
      vertical-align: middle;

      &:first-child {
        width: 1%;
      }

      &:last-child {
        padding-left: 1.2em;
      }
    }
  }

  .number {
    width: 3.5em;
    float: left;
    height: 3.5em;
    display: table;

    .circle {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      border: 3px solid $red;
      border-radius: 100%;

      span {
        font-size: 1.75em;
        color: $red;
        font-family: SegoeUIBold;
      }
    }
  }

  .question {
    float: left;
    width: calc(100% - 3.5em);

    .text {
      font-size: 1.25em;
      color: $red;
      font-family: SegoeUIBold;
      letter-spacing: .3em;
      text-transform: uppercase;
    }
  }
}