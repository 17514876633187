.article-content {
  display: table;
  width: 100%;
  position: relative;

  .status {
    position: relative;
    float: right;

    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }

  .rate {
    width: auto;
    margin: 1em 0;

    .rating {
      font-size: 2em;
    }
  }

  .region {
    .city {
      font-size: 1.25em;
    }
  }

  .entry-price {
    .title {
      font-size: .94em;
    }

    .value {
      font-size: 1.75em;
    }
  }

  img {
    float: left;
    margin: 0 2em 1.5em 0;
    width: 100%;
    max-width: 450px;

    @media screen and (max-width: $tablet) {
      float: none;
      margin: 0 auto 2em;
      display: block;
    }
  }

  .share {
    clear: both;
    margin-bottom: 2em;
    margin-top: 1em;
  }

  .button-primary {
    clear: both;
  }

  .amount {
    margin-top: 1em;
    margin-bottom: 1em;

    .single-amount {
      display: inline-block;

      &:not(:last-child) {
        margin-right: 1em;
      }
    }

    .title {
      font-size: .875em;
    }

    .value {
      font-size: 1.75em;
      white-space: nowrap;
    }

    .have {
      color: $text-grey;
    }

    .done {
      color: #17ba5d;
    }
  }
}