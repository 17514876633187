footer {
  position: relative;
  color: #fff;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    width: 50%;
    top: 0;
    background: $red;
    z-index: -1;
    bottom: 0;
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 50%;
    top: 0;
    background: $grey;
    z-index: -1;
    bottom: 0;
  }

  .container {
    @media screen and (max-width: $tablet) {
      padding: 0;
    }
  }

  .t-table {
    table-layout: fixed;

    @media screen and (max-width: $tablet) {
      display: block;
    }

    .t-td {
      width: 50%;
      vertical-align: middle;
      padding-top: 3em;
      padding-bottom: 3em;

      @media screen and (max-width: $tablet) {
        width: 100%;
        display: block;
        padding: 3em 15px;
      }
    }
  }

  .left-block {
    background: $red;
    position: relative;
    padding-right: 2em;

    @media screen and (max-width: $tablet) {
      text-align: center;
    }

    &:after {
      content: "\e906";
      font-family: icomoon;
      position: absolute;
      top: 40%;
      left: calc(100% - 2px);
      color: $red;
      transform: rotate(-90deg);
      font-size: 3em;

      @media screen and (max-width: $tablet) {
        display: none;
      }
    }

    img {
      margin-bottom: 1em;
      max-width: 200px;
    }

    .site-map {
      list-style: none;
      margin-bottom: 4em;
      padding: 0;
      line-height: 2;

      li {
        display: inline-block;

        a {
          text-transform: uppercase;
          font-size: 13px;

          &:hover {
            text-decoration: underline;
          }
        }

        &:not(:last-child) {
          a {
            margin-right: 1.5em;
          }
        }
      }
    }

    .donate {
      margin-bottom: 2em;

      .button-primary {
        &:hover {
          background: $grey;
          border-color: $grey;
        }
      }
    }

    .copyright {
      @media screen and (max-width: $tablet) {
        display: none;
      }
    }

    .button-primary {
      @media screen and (max-width: $tablet) {
        margin: 0 auto;
      }
    }
  }

  .copyright {
    font-size: 1.125em;
  }

  .right-block {
    background: $grey;
    padding-left: 7em;

    @media screen and (max-width: $laptop-s) {
      padding-left: 3em;
    }

    @media screen and (max-width: $tablet) {
      padding-left: 1em;
    }

    &>div {
      max-width: 270px;

      @media screen and (max-width: $tablet) {
        margin: 0 auto;
        text-align: center;
      }
    }

    .copyright {
      margin-top: 2em;
      display: none;

      @media screen and (max-width: $tablet) {
        display: block;
      }
    }

    .request {
      input {
        color: #fff;
        border-color: #fff;

        &::-webkit-input-placeholder {color:#fff;}
        &::-moz-placeholder          {color:#fff;}
        &:-moz-placeholder           {color:#fff;}
        &:-ms-input-placeholder      {color:#fff;}
      }

      .button-primary {
        border-color: #fff;
        color: #fff;

        &:hover {
          background: $red;
          border-color: $red;
        }
      }
    }
  }
}