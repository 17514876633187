.funds {
  .border-three>.t-table>.t-row>.t-td.single-fund {
    padding-top: 2em;

    img {
      float: left;
      margin-right: 4rem;

      @media screen and (max-width: $tablet) {
        margin-right: 1.5em;
        max-width: 60px;
      }
    }

    .icon-zoom-in {
      color: $red;
      font-size: 2em;
    }

    &:hover {
      h4 {
        color: $red;
      }
    }
  }
}