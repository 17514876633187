.services {
  .h-secondary {
    margin-bottom: 1.5em;
  }

  .single-service {
    text-align: center;
    padding: 0 2em;

    @media screen and (max-width: $tablet) {
      margin-bottom: 1em;
    }

    img {
      max-width: 100%;
      margin-bottom: .75em;
    }

    h4 {
      font-size: 1.5em;
      margin-top: 0;
      margin-bottom: .5em;
    }

    .text {
      font-size: .875em;
      color: $text-grey;
    }


  }
}