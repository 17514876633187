.services-list {
  margin-bottom: 2em;

  a {
    border: 1px solid $red;
    padding: .6em 2em .6em .6em;
    position: relative;
    display: block;
    width: 100%;
    max-width: 800px;
    font-size: 1.125em;
    margin-bottom: 1em;
    transition: .3s;

    span {
      position: absolute;
      right: .5em;
      font-size: 1.5em;
      top: 50%;
      transform: translateY(-50%);
      color: $red;
    }

    &:hover {
      background: $red;
      color: #fff;

      span {
        color: #fff;
      }
    }
  }
}