.experts {
  .t-table-outer {
    margin: -15px;

    .t-table {
      border-spacing: 15px;
      table-layout: fixed;

      .t-td {
        width: 25%;
        padding: 1em;
        border: 1px solid $border-grey-default;

        img {
          width: 100%;
          margin-bottom: 1em;
        }

        .title {
          font-size: 1.25em;
          letter-spacing: .3em;
          padding-left: .3em;
          text-align: center;
          text-transform: uppercase;
          margin-bottom: .3em;
        }

        .position {
          font-size: .875em;
          color: $text-grey;
          text-align: center;
        }

        @media screen and (max-width: $tablet) {
          width: 50%;
          display: inline-block;
          margin-bottom: 1em;
          border: none;

          &:nth-of-type(2n+1) {
            clear: both;
          }

          .title {
            letter-spacing: 0;
            padding: 0;
            font-size: 1.1em;
          }
        }
      }
    }
  }

  .text {
    margin-top: 2em;
  }
}