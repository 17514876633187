.questions-list {
  margin-top: 2em;

  .single-question {
    clear: both;
    margin-bottom: 3em;
    display: table;
    width: 100%;

    .t-table {
      .t-td {
        vertical-align: middle;

        &:first-child {
          width: 1%;
        }

        &:last-child {
          padding-left: 1.2em;
        }
      }
    }

    .number {
      width: 3.5em;
      float: left;
      height: 3.5em;
      display: table;

      .circle {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        border: 3px solid $red;
        border-radius: 100%;

        span {
          font-size: 1.75em;
          color: $red;
          font-family: SegoeUIBold;
        }
      }
    }

    .question {
      float: left;
      width: calc(100% - 3.5em);

      .text {
        font-size: 1.25em;
      }
    }

    .fields {
      padding-top: 1em;
    }

    input, textarea {
      width: 100%;
    }

    input.short {
      max-width: 5em;
    }

    textarea {
      min-height: 4em;
    }

    .upload {
      label {
        cursor: pointer;
        color: $text-grey;
        font-size: .875em;
        font-weight: normal;

        &:hover span:not(.icon-download) {
          text-decoration: underline;
        }

        span {
          display: inline-block;
          vertical-align: middle;
        }
      }

      .icon-download {
        display: inline-block;
        transform: rotate(180deg);
        color: $red;
        font-size: 1.5em;
        margin-left: .2em;
      }

      input {
        display: none;
      }
    }
  }

  &+button {
    float: right;
  }
}