.contact-us2 {
  display: table;
  margin: 3em auto 4em;

  .text {
    font-family: SegoeUISemiBold;
    font-size: 1.125em;
    text-align: center;
  }

  .button-primary {
    margin: 1em auto 0;
  }
}