.projects {
  margin-top: 1.5em;

  &.mini {
    .border-three>.t-table>.t-row>.t-td.single-project .desc {
      width: 100%;
    }
  }

  .small-h {
    font-size: 1.5em;
    margin-bottom: .5em;
    transition: .3s;
    min-height: 2.5em;
  }

  .rate .rating {
    font-size: 2em;
  }

  .border-three>.t-table>.t-row>.t-td.single-project {
    padding: 0;
    position: relative;

    @media screen and (max-width: $tablet) {
      display: table;
      width: 100%;
      border-spacing: 0;
      margin-bottom: 1em;
    }

    @media screen and (max-width: $mobile-xl) {
      max-width: 450px;
      margin: 0 auto 1em;
    }

    @media screen and (min-width: $mobile-xl) and (max-width: $tablet) {
      .status {
        left: -1px;
        right: auto;
      }
    }

    img {
      width: 100%;
      //height: 22.5em;
      //@include object-fit(cover);

      @media screen and (min-width: $mobile-xl) and (max-width: $tablet) {
        float: left;
        width: 33vw;
      }
    }

    .desc {
      padding: 1em 1.5em;
      display: table;
      width: 100%;
      border-spacing: 0;

      @media screen and (min-width: $mobile-xl) and (max-width: $tablet) {
        float: left;
        width: calc(100% - 33vw);
      }
    }

    .amount {
      margin-top: 1em;
      margin-bottom: 1em;

      .title {
        font-size: .875em;
      }

      .value {
        font-size: 1.75em;
        white-space: nowrap;
      }

      .have {
        color: $text-grey;
      }

      .done {
        color: #17ba5d;
      }
    }

    .share {
      float: right;
      margin: 1em 0;
    }
  }

  .single-project:hover {
    .small-h {
      color: $red;
    }
  }
}