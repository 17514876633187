body {
  font-size: 14px;
  font-family: SegoeUIRegular;

  @media screen and (min-width: $laptop-l) {
    font-size: 16px;
  }
}

a {
  text-decoration: none;
  color: inherit;

  &:hover {

  }
}

input, button {
  outline: none;
}

body .container {
  @media screen and (max-width: $laptop-s) {
    width: 100%;
  }
}

.main-wrapper {
  overflow: hidden;
}

p a {
  color: $red;
  
  &:hover {
    text-decoration: underline;
  }
}

.content {
  img {
    max-width: 100%;
  }
}