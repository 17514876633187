.news {
  overflow: hidden;

  .t-table-outer {
    @include table(30px);
    display: none;

    &:not(:last-child) {
      margin-bottom: 30px;

      @media screen and (max-width: $tablet) {
        margin-bottom: 0;
      }
    }

    .t-table {
      table-layout: fixed;
    }

    .t-td {
      border: 1px solid $border-grey;
      vertical-align: top;
      position: relative;
      padding-bottom: 2.5em;
      transition: .3s;

      @media screen and (max-width: $tablet) {
        display: block;
        width: 100% !important;
        margin-bottom: 15px;
      }

      &:hover {
        //transform: translateY(-3px);

        @media screen and (max-width: $tablet) {
          transform: none;
        }
      }

      &:first-child {
        width: 65%;

        @media screen and (max-width: $laptop-s) {
          width: 50%;
        }
      }

      &:last-child {
        width: 35%;

        @media screen and (max-width: $laptop-s) {
          width: 50%;
        }
      }

      .image {
        img {
          width: 100%;
          height: 15em;
          @include object-fit(cover);
        }
      }

      .desc {
        padding: 1em;
        display: table;
        width: 100%;
        border-spacing: 0;

        h4 {
          font-family: SegoeUISemiBold;
          text-transform: uppercase;
          margin-bottom: .8em;
          font-size: 1em;
          line-height: 1.4;
          transition: .3s;

          &:hover {
            color: $red;
          }

          &.big-title {
            font-size: 1.75em;

            @media screen and (max-width: $laptop-s) {
              font-size: 1.3em;
            }
          }
        }

        .share {
          position: absolute;
          right: 1em;
          bottom: 1em;
        }
      }
    }

    &:nth-of-type(2n) {
      .t-td {
        &:first-child {
          width: 33%;

          @media screen and (max-width: $laptop-s) {
            width: 50%;
          }
        }

        &:last-child {
          width: 67%;

          @media screen and (max-width: $laptop-s) {
            width: 50%;
          }
        }
      }
    }
  }

  .button-primary {
    margin: 3em auto 0;

    @media screen and (max-width: $tablet) {
      margin: 0 auto;
    }
  }
}