body {
  #calendar {
    .modal-content {
      padding: 3em 0 0 0;
    }

    .events-list {
      max-width: 330px;
      width: 100%;
      max-height: 250px;
      overflow-x: hidden;
      overflow-y: scroll;
      border-top: 1px solid $red;

      &::-webkit-scrollbar-track
      {
        background-color: transparent;
      }

      &::-webkit-scrollbar
      {
        width: 4px;
        background-color: transparent;
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb
      {
        background-color: $red;
        border-radius: 5px;
      }

      .single-event {
        padding: 1em;
        display: table;
        width: 100%;

        &:not(:first-child) {
          border-top: 1px solid $red;
        }

        .event-date {
          float: left;
          width: 5em;
          color: #000;
          padding-right: 1em;

          span {
            display: block;
            text-align: center;

            &:first-child {
              font-size: 2.25em;
              line-height: 1;
            }

            &:last-child {
              font-size: .875em;
              padding: 0;
            }
          }
        }

        .desc {
          float: left;
          width: calc(100% - 5em);

          h3 {
            font-size: 1.2em;
            text-transform: uppercase;
            margin: 0 0 .5rem;
          }

          a {
            font-size: .875em;
            color: $red;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .calendar {
    max-width: 330px;
    margin: 0 auto;
    padding: 0 1.5em 1.5em;

    h2 {
      font-size: 1.5em;
      color: $red;
    }
  }

  th.fc-day-header {
    color: $red;
  }

  td.fc-event-container {
    display: none;
  }

  .fc .fc-toolbar>*>* {
    background: none;
    border: none;
    box-shadow: none;
    padding: 0;
  }

  .fc table * {
    border: none !important;
  }

  .fc-basic-view .fc-body .fc-row {
    min-height: 1em;
    height: auto !important;
  }

  .fc-view-container *, .fc-view-container :after, .fc-view-container :before {
    height: auto !important;
  }

  .fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
    float: none;
    padding: .3em;
    display: block;
  }

  .fc-row .fc-content-skeleton {
    padding: 0;
  }

  .fc-row .fc-content-skeleton td, .fc-row .fc-helper-skeleton td {
    text-align: center;
    vertical-align: middle;
    pointer-events: none;

    &.fc-day-top.active {
      background-color: #9c1114;
      color: #fff;
      cursor: pointer;
      pointer-events: auto;
    }
  }
}