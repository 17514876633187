.experts1 {
  .single-expert {
    border: 1px solid $border-grey-default;
    padding: 1.5em;
    margin-bottom: 1.5em;
  }

  img {
    width: 100%;

    @media screen and (max-width: $tablet) {
      max-width: 250px;
      margin: 0 auto 1em;
      display: table;
    }
  }

  .desc {
    .name {
      font-size: 1.25em;
      font-family: SegoeUISemiBold;
      margin-bottom: .2em;
    }

    .mail {
      margin-bottom: .5em;

      a {
        color: $text-grey;
      }
    }

    .text {
      font-size: .93em;
    }
  }
}