.filter {
  margin-bottom: 3em;

  .filter-row {
    display: table;
    width: 100%;
    margin-bottom: 1.5em;

    .single-filter {
      display: inline-block;

      &>span {
        font-size: 1.125em;
        margin-right: .5em;
      }

      &:not(:last-child) {
        margin-right: 2.5em;
        margin-bottom: 1em;
      }

      .amount {
        max-width: 8em;
      }
    }

    .submit-block {
      float: right;
      display: table;

      @media screen and (max-width: $tablet) {
        display: block;
        float: none;
        clear: both;
      }

      &>* {
        display: inline-block;
        vertical-align: middle;
      }

      button {
        margin-right: 1.5em;
      }

      .reset {
        color: $text-grey;
        font-size: .875em;
        cursor: pointer;
        transition: .3s;

        .icon-close {
          font-size: 1.5em;
          margin-right: .3rem;
        }

        &>span {
          display: inline-block;
          vertical-align: middle;
          line-height: 1;
        }

        &:hover {
          color: $red;
        }
      }
    }
  }
}