.t-table {
  display: table;
  width: 100%;

  .t-row {
    display: table-row;
  }

  .t-td {
    display: table-cell;

    &.min {
      width: 1%;
    }
  }
}