/** SegoeUIRegular **/
@font-face {
  font-family: 'SegoeUIRegular';
  src: url('/assets/fonts/SegoeUIRegular/segoeui.eot');
  src: local('☺'), url('/assets/fonts/SegoeUIRegular/segoeui.woff') format('woff'),
  url('/assets/fonts/SegoeUIRegular/segoeui.ttf') format('truetype'),
  url('/assets/fonts/SegoeUIRegular/segoeui.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

/** SegoeUIBold **/
@font-face {
  font-family: 'SegoeUIBold';
  src: url('/assets/fonts/SegoeUIBold/segoeuib.eot');
  src: local('☺'), url('/assets/fonts/SegoeUIBold/segoeuib.woff') format('woff'),
  url('/assets/fonts/SegoeUIBold/segoeuib.ttf') format('truetype'),
  url('/assets/fonts/SegoeUIBold/segoeuib.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

/** SegoeUISemiBold **/
@font-face {
  font-family: 'SegoeUISemiBold';
  src: url('/assets/fonts/SegoeUISemiBold/seguisb.eot');
  src: local('☺'), url('/assets/fonts/SegoeUISemiBold/seguisb.woff') format('woff'),
  url('/assets/fonts/SegoeUISemiBold/seguisb.ttf') format('truetype'),
  url('/assets/fonts/SegoeUISemiBold/seguisb.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

/** SegoeUIItalic **/
@font-face {
  font-family: 'SegoeUIItalic';
  src: url('/assets/fonts/SegoeUIItalic/segoeuii.eot');
  src: local('☺'), url('/assets/fonts/SegoeUIItalic/segoeuii.woff') format('woff'),
  url('/assets/fonts/SegoeUIItalic/segoeuii.ttf') format('truetype'),
  url('/assets/fonts/SegoeUIItalic/segoeuii.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

/** SegoeUILight **/
@font-face {
  font-family: 'SegoeUILight';
  src: url('/assets/fonts/SegoeUILight/segoeuil.eot');
  src: local('☺'), url('/assets/fonts/SegoeUILight/segoeuil.woff') format('woff'),
  url('/assets/fonts/SegoeUILight/segoeuil.ttf') format('truetype'),
  url('/assets/fonts/SegoeUILight/segoeuil.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

/** SegoeUIBoldItalic **/
@font-face {
  font-family: 'SegoeUIBoldItalic';
  src: url('/assets/fonts/SegoeUIBoldItalic/segoeuiz.eot');
  src: local('☺'), url('/assets/fonts/SegoeUIBoldItalic/segoeuiz.woff') format('woff'),
  url('/assets/fonts/SegoeUIBoldItalic/segoeuiz.ttf') format('truetype'),
  url('/assets/fonts/SegoeUIBoldItalic/segoeuiz.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'icomoon';
  src:  url('/assets/fonts/icoMoon/icomoon.eot?csqp20');
  src:  url('/assets/fonts/icoMoon/icomoon.eot?csqp20#iefix') format('embedded-opentype'),
  url('/assets/fonts/icoMoon/icomoon.ttf?csqp20') format('truetype'),
  url('/assets/fonts/icoMoon/icomoon.woff?csqp20') format('woff'),
  url('/assets/fonts/icoMoon/icomoon.svg?csqp20#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-checkmark:before {
  content: "\e90e";
}
.icon-search:before {
  content: "\e908";
}
.icon-phone:before {
  content: "\e915";
}
.icon-at-sign:before {
  content: "\e913";
}
.icon-zoom-in:before {
  content: "\e90d";
}
.icon-heart:before {
  content: "\e90b";
}
.icon-close:before {
  content: "\e90f";
}
.icon-up:before {
  content: "\e902";
}
.icon-right:before {
  content: "\e903";
}
.icon-left:before {
  content: "\e904";
}
.icon-down:before {
  content: "\e905";
}
.icon-down2:before {
  content: "\e906";
}
.icon-down3:before {
  content: "\e911";
}
.icon-up3:before {
  content: "\e912";
}
.icon-share:before {
  content: "\e907";
}
.icon-download:before {
  content: "\e90c";
}
.icon-time:before {
  content: "\e90a";
}
.icon-at:before {
  content: "\e914";
}
.icon-star:before {
  content: "\e910";
}
.icon-twitter:before {
  content: "\e909";
}
.icon-facebook:before {
  content: "\e900";
}
.icon-vk:before {
  content: "\e901";
}
