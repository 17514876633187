.trainings {
  .desc {
    font-size: 1.125em;
  }

  .types {
    .row > div {
      margin-bottom: 3em;

      &:nth-of-type(3n+1) {
        clear: both;
      }
    }

    h4 {
      font-size: 1.125em;
      font-family: SegoeUISemiBold;
      margin-bottom: 1em;
      text-transform: uppercase;
    }
  }
}