.request {
  &>.title {
    font-size: 1.5em;
    margin-bottom: .5em;
    text-transform: uppercase;
  }

  .text {
    font-size: .94em;
    margin-bottom: 1em;
  }

  input {
    background: none;
    border: 1px solid;
    font-size: .94em;
    padding: .5em .8em;
    width: 100%;
    border-radius: 0;
    -webkit-appearance: none;
    box-shadow: none;

    &:focus {
      border-color: $red;
    }
  }

  button {
    width: 100%;
  }
}