.countries {
  margin-bottom: 2.5em;

  td {
    padding: 3rem 0;
    vertical-align: middle;

    @media screen and (max-width: $tablet) {
      display: block;
      padding: 1.5rem 0;
    }

    &:first-child {
      border-right: 2px solid $red;
      padding-right: 5rem;
      font-size: 1.125em;
      font-family: SegoeUIBold;

      @media screen and (max-width: $tablet) {
        padding-right: 0;
        border-right: none;
      }
    }

    &:last-child {
      padding-left: 6rem;

      @media screen and (max-width: $tablet) {
        padding-left: 0;
        border-bottom: 2px solid $red;
      }
    }
  }
}