.breadcrumbs {
  margin-bottom: 2em;
  padding: 0;
  list-style: none;

  li {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 1em;
    }
  }

  a {
    font-size: 1.125em;
    text-transform: uppercase;
    letter-spacing: .3em;
    transition: .3s;

    &:hover {
      color: $red;
    }

    .icon-left {
      font-size: 1.2em;
      display: inline-block;
      transform: translateY(.1em);
    }
  }
}